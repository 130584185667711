import classNames from 'classnames'
import debounce from 'lodash.debounce'
import { MutableRefObject, useEffect, useState } from 'react'

import styles from './use_animation.module.scss'

export type BounceOptions = {
  isDisabled?: boolean
  percentFromTop?: number
  timeout?: number
  customTrigger?: boolean
  useCustomTrigger?: boolean
}

type Props = {
  ref: MutableRefObject<HTMLElement | HTMLDivElement | null>
  className?: string
  options?: BounceOptions
}

const DEFAULT_PERCENT_FROM_TOP = 80
const DEFAULT_TIMEOUT = 0

const useBounceAnimations = ({ ref, className, options }: Props) => {
  const [newClassName, setNewClassName] = useState('')
  const percentFromTop = options?.percentFromTop || DEFAULT_PERCENT_FROM_TOP
  const timeout = options?.timeout || DEFAULT_TIMEOUT

  const resultClassName = classNames(className, newClassName)

  useEffect(() => {
    if (options?.isDisabled) return undefined

    const handleScroll = debounce(() => {
      const element = ref.current as unknown as HTMLElement
      const elementTopPosition = element?.getBoundingClientRect().top
      const indentFromTop = (window.innerHeight / 100) * percentFromTop
      const defaultTrigger =
        elementTopPosition < indentFromTop && elementTopPosition > 0

      const bounceTrigger = options?.useCustomTrigger
        ? options?.customTrigger
        : defaultTrigger

      if (bounceTrigger) {
        setTimeout(() => {
          setNewClassName(styles.bounce_in)
        }, timeout)

        window.removeEventListener('scroll', handleScroll)
      }
    }, 50)

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options?.customTrigger,
    options?.isDisabled,
    options?.useCustomTrigger,
    percentFromTop,
    timeout,
  ])

  if (options?.isDisabled) return ''

  return resultClassName
}

export default useBounceAnimations
