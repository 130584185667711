import { useRouter } from 'next/router'
import { LocalesType } from 'promova-student/_types/locales'

import { DISABLED_SEO_LOCALES } from '@promova/config'

type UseRobotsNoIndex = (
  initialValue?: string,
  locales?: string[],
  pageLocale?: LocalesType
) => string | undefined

const useRobotsNoIndex: UseRobotsNoIndex = (
  initialValue,
  locales = [],
  pageLocale = undefined
) => {
  const { locale } = useRouter()

  // this condition will be true if a page has no translation to the current locale
  if (pageLocale && pageLocale !== locale) {
    return 'noindex, nofollow'
  }

  if (initialValue && !DISABLED_SEO_LOCALES.includes(locale as string)) {
    return initialValue
  }

  if (
    DISABLED_SEO_LOCALES.includes(locale as string) ||
    locales?.includes(locale as string)
  ) {
    return 'noindex, nofollow'
  }

  return undefined
}

export default useRobotsNoIndex
