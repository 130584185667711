import { FC, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'

import useHorizontalScroll from '@promova/utils/customHooks/useHorizontalScroll'

import { useAppSelector } from '@reduxStore/hooks'

import useLandingInfo from '@hooks/useLandingInfo'

import { sendAnalytics } from '@utils/analytics'

import styles from './horizontal_scroller.module.scss'

type Props = {
  title?: string
  className?: string
  eventPlace?: string
}

const HorizontalScroller: FC<Props> = ({
  title,
  children,
  className = '',
  eventPlace,
}) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  const scrollRef = useHorizontalScroll()
  const isScrolledRef = useRef(false)
  const amplitudeInited = useAppSelector(
    (state) => state?.amplitude?.amplitudeInited
  )
  const { LANDING_PATH } = useLandingInfo()

  const handleScroll = () => {
    if (isScrolledRef.current) return
    isScrolledRef.current = true

    if (amplitudeInited) {
      sendAnalytics({
        eventName: 'gen_scrolled_info',
        data: {
          page_path: LANDING_PATH,
          place: eventPlace || 'horizontal scroll block',
        },
      })
    }
  }

  return (
    <div
      className={`${styles.section} ${className}`}
      ref={isDesktop ? scrollRef : undefined}
      onScroll={handleScroll}
    >
      {title && (
        <div className={styles.title_wrapper}>
          <h2 className={styles.title}>{title}</h2>
        </div>
      )}
      <div className={styles.cards_wrapper}>{children}</div>
    </div>
  )
}

export default HorizontalScroller
