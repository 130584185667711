import classNames from 'classnames'
import Image, { StaticImageData } from 'next/image'
import { FC, useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Parallax } from 'react-scroll-parallax'

import {
  ApproachTypes,
  SectionEventProps,
  StrapiRecord,
} from '@_types/landings'

import FitSizeText from '@elements/FitSizeText/FitSizeText'
import StrapiImage from '@elements/StrapiImage/StrapiImage'

import styles from './approach_section.module.scss'
import bookImage from './image/bookImage.png'
import robotImage from './image/robotImage.png'

interface Props {
  approachTitle: string
  approachSubtitle?: string
  approachList: ApproachTypes[]
  customImage?: {
    image1: StaticImageData | StrapiRecord
    image1ClassName?: string
    image2: StaticImageData | StrapiRecord
    image2ClassName?: string
    isFromStrapi?: boolean
  }
  sectionOrder?: number
  disabled?: boolean
  event?: SectionEventProps
  offsetToHideFirstImage?: number
  sectionClassName?: string
}

const ApproachSection: FC<Props> = ({
  approachList,
  approachTitle,
  approachSubtitle,
  customImage,
  sectionOrder,
  disabled,
  event,
  sectionClassName,
  offsetToHideFirstImage = 3.0,
}) => {
  const [isScroll, setIsScroll] = useState(false)
  const sectionRef = useRef<HTMLElement>(null)
  const listRef = useRef<HTMLUListElement>(null)
  const [imageUrl, setImageUrl] = useState(customImage?.image1 || robotImage)
  const [isVisibleImage, setIsVisibleImage] = useState(false)
  const [isAlignSection, setIsAlignSection] = useState(false)

  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)',
  })

  const isMobileM = useMediaQuery({
    query: '(max-width: 375px)',
  })

  useEffect(() => {
    const sectionTop = sectionRef.current?.getBoundingClientRect()?.top
    const section = sectionRef.current

    if (isAlignSection && sectionTop !== 0) {
      window.scrollTo({
        top: section?.offsetTop,
        left: 0,
        behavior: 'smooth',
      })
    }

    if (sectionTop === 0) {
      setIsAlignSection(false)
    }
  }, [isAlignSection])

  useEffect(() => {
    const list = listRef.current
    // this is necessary to understand when the block is visible and to start scrolling in the block itself.
    const handleScroll = (): void => {
      if (list && list.childNodes[0] instanceof HTMLElement && isDesktop) {
        const childHeight = Number(list.childNodes[0]?.offsetHeight)
        const childrenLength = Number(list.childNodes?.length)
        const beforeLastItemCoefficient =
          list.scrollTop - childHeight * (childrenLength - 1.8)
        const lastItemCoefficient = childrenLength - 0.7

        const isShowImage =
          (list.scrollTop - childHeight / (childrenLength - 1) > 0 &&
            list.scrollTop - childHeight * (childrenLength - 1) < 0) ||
          beforeLastItemCoefficient > 0
        const isHiddenImage =
          (list.scrollTop -
            childHeight * (childrenLength - offsetToHideFirstImage) >
            0 &&
            beforeLastItemCoefficient < 0) ||
          list.scrollTop - childHeight * lastItemCoefficient > 0 ||
          list.scrollTop === 0
        const isSetTutorImage = beforeLastItemCoefficient + childHeight / 2 > 0

        if (isShowImage) {
          setImageUrl(customImage?.image1 || bookImage)
          setIsVisibleImage(true)
        }
        if (isHiddenImage) {
          setIsVisibleImage(false)
        }
        if (isSetTutorImage) {
          setImageUrl(customImage?.image2 || robotImage)
        }
      }
    }

    list?.addEventListener('scroll', handleScroll)

    return () => {
      list?.removeEventListener('scroll', handleScroll)
    }
  }, [
    listRef,
    isDesktop,
    customImage?.image1,
    customImage?.image2,
    offsetToHideFirstImage,
  ])

  useEffect(() => {
    const section = sectionRef.current

    const handleScroll = (): void => {
      if (section && isDesktop) {
        const sectionPositionTop = Number(section.getBoundingClientRect().top)
        const indentTop = 320
        const indentBottom = section.offsetHeight / 5
        const isSetScroll =
          sectionPositionTop - indentTop <= 0 &&
          sectionPositionTop + indentBottom >= 0

        if (isSetScroll) {
          setIsScroll(true)
          setIsAlignSection(true)
        } else {
          setIsScroll(false)
          setIsAlignSection(false)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [sectionRef, isDesktop])

  if (disabled) return null

  const listClassNames = classNames(styles.list, {
    [styles.list_scroll]: isScroll,
  })

  const figureClassName = classNames(
    styles.figure,
    customImage?.image1ClassName,
    {
      [styles.figure_visible]: isVisibleImage,
      [styles.figure_tutor]: imageUrl === (bookImage || customImage?.image2),
      [customImage?.image2ClassName as string]:
        imageUrl === customImage?.image2,
    }
  )

  const titleClassName = classNames(styles.title, {
    [styles.title_less_spaced]: !!approachSubtitle,
  })

  const isCustomImage1ClassName = !!customImage?.image1ClassName
  const isCustomImage2ClassName = !!customImage?.image2ClassName

  const firstMobileImageClassName = classNames({
    [customImage?.image1ClassName as string]: isCustomImage1ClassName,
    [styles.robot_image]: !isCustomImage1ClassName,
  })

  const secondMobileImageClassName = classNames({
    [customImage?.image2ClassName as string]: isCustomImage2ClassName,
    [styles.tutor_image]: !isCustomImage2ClassName,
  })

  const sectionClassNames = classNames(styles.section, sectionClassName)

  return (
    <section
      className={sectionClassNames}
      ref={sectionRef}
      style={{ order: sectionOrder }}
    >
      <div
        data-place={event?.place}
        ref={event?.divRef}
        className={styles.title_wrap}
      >
        {isMobileM ? (
          <div>
            <FitSizeText
              Tag="h2"
              text={approachTitle}
              wrapperClassName={titleClassName}
            />
            {approachSubtitle && (
              <FitSizeText
                text={approachSubtitle}
                wrapperClassName={styles.subtitle}
                Tag="span"
              />
            )}
          </div>
        ) : (
          <div>
            <h2 className={titleClassName}>{approachTitle}</h2>
            {approachSubtitle && (
              <span className={styles.subtitle}>{approachSubtitle}</span>
            )}
          </div>
        )}

        {isDesktop && (
          <figure className={figureClassName}>
            {customImage?.isFromStrapi ? (
              <StrapiImage image={imageUrl as StrapiRecord} layout="fill" />
            ) : (
              <Image src={imageUrl as StaticImageData} alt="" />
            )}
          </figure>
        )}
      </div>
      <ul className={listClassNames} ref={listRef}>
        {!isDesktop && (
          <>
            <Parallax speed={3} className={firstMobileImageClassName}>
              <figure>
                {customImage?.isFromStrapi ? (
                  <StrapiImage image={customImage.image1} layout="fill" />
                ) : (
                  <Image
                    src={(customImage?.image1 as StaticImageData) || robotImage}
                    alt=""
                  />
                )}
              </figure>
            </Parallax>
            <Parallax speed={3} className={secondMobileImageClassName}>
              <figure className={customImage?.image2ClassName}>
                {customImage?.isFromStrapi ? (
                  <StrapiImage image={customImage.image2} layout="fill" />
                ) : (
                  <Image
                    src={(customImage?.image2 as StaticImageData) || bookImage}
                    alt=""
                  />
                )}
              </figure>
            </Parallax>
          </>
        )}
        {approachList?.map(({ text, order }: any) => (
          <li key={text} className={styles.item}>
            <p className={styles.item_order}>- 0{order}</p>
            <p className={styles.item_text}>{text}</p>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default ApproachSection
