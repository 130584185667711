import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import { format } from 'date-fns'
import { useRouter } from 'next/router'
import { FC } from 'react'

import { LowercaseMonthLocales } from '@constants_folder/lowercaseMonthLocales'

import { Review } from '@_types/landings'

import { useLocalDateFormat } from '@hooks/useDateFnsDictionary'

import StrapiImage from '@elements/StrapiImage/StrapiImage'

import StarIcon from '@icons/Star.svg'

import styles from './review_item.module.scss'

type Props = {
  review: Review
}

const totalPossibleStars = 5

const ReviewItem: FC<Props> = ({ review }) => {
  const { locale, defaultLocale } = useRouter()

  const { dateFormat, dateFnsLang } = useLocalDateFormat()

  const greyStarsQuantity = totalPossibleStars - review.stars

  const author = review?.author

  const isLowerCaseMonthLocale = LowercaseMonthLocales.includes(locale || '')
  const dateClassnames = classNames(styles.review_date, {
    [styles.lowercase]: isLowerCaseMonthLocale,
  })

  return (
    <div className={styles.review_item}>
      <div className={styles.review_header}>
        <div className={styles.review_info}>
          {author?.flag?.data && (
            <div className={styles.review_img}>
              <StrapiImage
                image={author?.flag}
                layout="fill"
                fit="contain"
                priority
              />
            </div>
          )}
          <div className={styles.review_info_column}>
            <span className={styles.user_name}>{author?.name}</span>
            <span className={dateClassnames}>
              {format(new Date(review.date), dateFormat.fullWithYear, {
                locale: dateFnsLang,
              })}
            </span>
          </div>
        </div>
        <div className={styles.stars_box}>
          {Array.from({ length: review.stars }).map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <StarIcon className={styles.star_icon_green} key={`${i}_star`} />
          ))}
          {!!greyStarsQuantity &&
            Array.from({ length: greyStarsQuantity }).map((_, index) => (
              <StarIcon
                className={styles.star_icon_grey}
                // eslint-disable-next-line react/no-array-index-key
                key={`star_${index}`}
              />
            ))}
        </div>
      </div>
      <span
        className={styles.review_text}
        dangerouslySetInnerHTML={{ __html: review?.textExt || review?.text }}
      />
      {locale !== defaultLocale && (
        <p className={styles.localization_info}>
          <Trans>*Translated from english</Trans>
        </p>
      )}
    </div>
  )
}

export default ReviewItem
