import { useRouter } from 'next/router'

import { AM_PM_COUNTRIES, dictionariesDateFNS } from '@constants'

import { useQueryCountry } from '@utils/useQueryCountry'

const useDateFnsDictionary = () => {
  const router = useRouter()
  const locale = router?.locale || 'en'
  const lang = dictionariesDateFNS[locale]

  return lang
}

export interface DateFormat {
  long: string
  full: string
  medium: string
  shortWithWeek: string
  shortWithYear: string
  fullWithYear: string
  monthWithHour: string
}

// don't use this hook in array element, it will cause multiple api calls
export const useLocalDateFormat = () => {
  const router = useRouter()
  const locale = router?.locale || 'en'
  const lang = dictionariesDateFNS[locale]

  const { countryCode } = useQueryCountry()

  const isAMPMTimeFormat = AM_PM_COUNTRIES.includes(countryCode)
  const MILITARY_FORMAT = 'HH:mm'
  const AM_PM_FORMAT = 'hh:mm aa'
  const TIME_FORMAT = isAMPMTimeFormat ? AM_PM_FORMAT : MILITARY_FORMAT

  const defaultDateFormat = {
    long: `${TIME_FORMAT}, EEE, d MMM yyyy`,
    full: `${TIME_FORMAT}, EEE, d MMM`,
    medium: 'EEE, d MMM yyyy',
    shortWithWeek: 'EEE, d MMM',
    shortWithYear: 'd MMM yyyy',
    fullWithYear: 'd MMMM yyyy',
    monthWithHour: `MMM d, ${TIME_FORMAT}`,
  }

  const customFormats: Record<string, DateFormat> = {
    en: {
      long: `yyyy, EEE, MMM d, ${TIME_FORMAT}`,
      full: `EEE, MMM d, ${TIME_FORMAT}`,
      medium: 'EEE, MMM d, yyyy',
      shortWithWeek: 'EEE, MMM d',
      shortWithYear: 'MMM d, yyyy',
      fullWithYear: 'MMM d, yyyy',
      monthWithHour: `MMM d, ${TIME_FORMAT}`,
    },
    tr: {
      long: `d MMM, EEE, yyyy, ${TIME_FORMAT}`,
      full: `d MMM, EEE, ${TIME_FORMAT}`,
      medium: 'd MMM, EEE, yyyy',
      shortWithWeek: 'd MMM, EEE',
      shortWithYear: 'd MMM yyyy',
      fullWithYear: 'd MMMM yyyy',
      monthWithHour: `d MMMM, ${TIME_FORMAT}`,
    },
    uk: {
      long: `${TIME_FORMAT}, EEEEEE, d MMM yyyy`,
      full: `${TIME_FORMAT}, EEEEEE, d MMM`,
      medium: 'EEEEEE, d MMM yyyy',
      shortWithWeek: 'EEEEEE, d MMM',
      shortWithYear: 'd MMM yyyy',
      fullWithYear: 'd MMMM yyyy',
      monthWithHour: `${TIME_FORMAT}, d MMMM`,
    },
    es: {
      long: `${TIME_FORMAT}, EEE, d MMM yyyy`,
      full: `${TIME_FORMAT}, EEE, d MMM`,
      medium: 'EEE, d MMM yyyy',
      shortWithWeek: 'EEE, d MMM',
      shortWithYear: 'd MMM yyyy',
      fullWithYear: 'd MMMM yyyy',
      monthWithHour: `${TIME_FORMAT}, d MMMM`,
    },
    de: {
      long: `${TIME_FORMAT}, EEEEEE. do MMM yyyy`,
      full: `${TIME_FORMAT}, EEEEEE. do MMM`,
      medium: 'EEEEEE. do MMM yyyy',
      shortWithWeek: 'EEEEEE. do MMM',
      shortWithYear: 'do MMM yyyy',
      fullWithYear: 'do MMMM yyyy',
      monthWithHour: `${TIME_FORMAT}, do MMMM`,
    },
    pl: {
      long: `${TIME_FORMAT}, EEE, d MMMM yyyy`,
      full: `${TIME_FORMAT}, EEE, d MMMM`,
      medium: 'EEE, d MMMM yyyy',
      shortWithWeek: 'EEE, d MMMM',
      shortWithYear: 'd MMMM yyyy',
      fullWithYear: 'd MMMM yyyy',
      monthWithHour: `${TIME_FORMAT}, d MMMM`,
    },
    pt: {
      long: `${TIME_FORMAT}, EEE, d MMM yyyy`,
      full: `${TIME_FORMAT}, EEE, d MMM`,
      medium: 'EEE, d MMM yyyy',
      shortWithWeek: 'EEE, d MMM',
      shortWithYear: 'd MMM yyyy',
      fullWithYear: 'd MMMM yyyy',
      monthWithHour: `${TIME_FORMAT}, d MMMM`,
    },
  }

  const dateFormat = customFormats[locale] || defaultDateFormat

  return {
    dateFormat,
    englishDateFormat: customFormats.en,
    dateFnsLang: lang,
    timeFormat: TIME_FORMAT,
  }
}

export default useDateFnsDictionary
