import classNames from 'classnames'
import debounce from 'lodash.debounce'
import { FC, useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import YouTube, { YouTubePlayer, YouTubeProps } from 'react-youtube'

import Icon from '@promova/ui/components/Icon/Icon'

import { useAppSelector } from '@reduxStore/hooks'

import { SectionProps, StrapiRecord } from '@_types/landings'

import useLandingInfo from '@hooks/useLandingInfo'

import { sendAnalytics } from '@utils/analytics'

import StrapiImage from '@elements/StrapiImage/StrapiImage'

import styles from './youtube_video_section.module.scss'

type Props = {
  textBefore: string
  textAfter: string
  videoId: string
  preview: StrapiRecord
  enableClickPlayAnalytics?: boolean
  // Force the text layout to be column and not row no matter what screen size it is.
  // Used for the YouTube videos to be rendered in the modal pop-ups
  // (modal size is smaller than the device screen size).
  verticalText?: boolean
  showPreview?: boolean
} & SectionProps

const YoutubeVideoSection: FC<Props> = ({
  videoId,
  textBefore,
  textAfter,
  preview,
  order,
  event,
  disabled,
  enableClickPlayAnalytics,
  // default value not to break old landings
  verticalText = false,
  showPreview = true,
}) => {
  const [player, setPlayer] = useState<YouTubePlayer>()
  const [isStarted, setIsStarted] = useState(false)
  const [isOnScreen, setIsOnScreen] = useState(false)
  const amplitudeInited = useAppSelector(
    (state) => state?.amplitude?.amplitudeInited
  )

  const { LANDING_PATH } = useLandingInfo()

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  })

  const playerRef = useRef(null)
  const circleRef = useRef(null)

  useEffect(() => {
    const handleScroll = debounce(() => {
      const element = circleRef.current as unknown as HTMLElement

      if (
        element &&
        element.getBoundingClientRect().top < (window.innerHeight / 100) * 70 &&
        element.getBoundingClientRect().top > (window.innerHeight / 100) * 30
      ) {
        setIsOnScreen(true)
      } else {
        setIsOnScreen(false)
      }
    }, 20)

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (disabled || !videoId) return null

  const handlePlay = () => {
    player?.playVideo()
    setIsStarted(true)

    if (amplitudeInited && enableClickPlayAnalytics) {
      sendAnalytics({
        eventName: 'gen_clicked_play_video',
        data: {
          page_path: LANDING_PATH,
          place: 'tutor-intro',
          video_name: textBefore + textAfter,
        },
      })
    }
  }

  const handleEnd = () => {
    setIsStarted(false)
  }

  const onReady: YouTubeProps['onReady'] = (e) => {
    setPlayer(e.target)
  }

  const previewWrapperClassNames = classNames(styles.preview_wrapper, {
    [styles.preview_hide]: isStarted,
    [styles.on_screen]: isOnScreen,
    // vertical alignment of a before and after texts.
    // Used for rendering the YouTube video player in a modal pop-up,
    // where the regular X-axis(row) layout looks clunky.
    [styles.vertical_text]: verticalText,
  })

  const id = videoId?.split('v=')[1]

  return (
    <section
      className={styles.section}
      data-place={event?.place}
      ref={event?.ref}
      style={{ order }}
    >
      <YouTube
        ref={playerRef}
        videoId={id || videoId}
        className={styles.player}
        iframeClassName={styles.iframe}
        onReady={onReady}
        onEnd={handleEnd}
      />
      {showPreview && (
        <div
          className={previewWrapperClassNames}
          onClick={handlePlay}
          onKeyDown={handlePlay}
          role="button"
          tabIndex={0}
        >
          <figure className={styles.preview}>
            <StrapiImage
              image={preview}
              layout="fill"
              fit="cover"
              unoptimized
            />
          </figure>
          <p className={styles.title_first}>{textBefore}</p>
          <div className={styles.circle} ref={circleRef}>
            <Icon
              icon="play_solid"
              className={styles.icon}
              size={isMobile ? 28 : 40}
              scaled
            />
          </div>
          <p className={styles.title}>{textAfter}</p>
        </div>
      )}
    </section>
  )
}

export default YoutubeVideoSection
