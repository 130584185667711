import { FC } from 'react'
import Modal from 'react-modal'

import { SectionVideoTypes } from '@_types/landings'

import YoutubeVideoSection from '@templates/Landing/Online/layout/YoutubeVideoSection/YoutubeVideoSection'

import styles from './modal_with_youtube.module.scss'


interface Props {
  isOpen: boolean
  onClose: () => void
  videoProps: SectionVideoTypes
}

const ModalWithYoutube: FC<Props> = ({ isOpen, onClose, videoProps }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    overlayClassName={styles.overlay}
    className={styles.container}
    bodyOpenClassName={styles.open_modal}
    style={{ content: { backgroundColor: '#fff' } }}
  >
    <button
      type="button"
      onClick={onClose}
      className={styles.close_btn}
      aria-label="Cancel"
    >
      <div className={styles.close_icon} />
    </button>
    <div className={styles.info}>
      <YoutubeVideoSection
        textBefore={videoProps.sectionVideoTextBefore}
        textAfter={videoProps.sectionVideoTextAfter}
        videoId={videoProps.sectionVideoId}
        preview={videoProps.sectionVideoPreview}
        order={videoProps.sectionVideoOrder}
        disabled={videoProps.sectionVideoDisabled}
        enableClickPlayAnalytics={false}
        verticalText
        showPreview={false}
      />
    </div>
  </Modal>
)
export default ModalWithYoutube
