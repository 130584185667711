import { StaticImageData } from 'next/image'
import { FC } from 'react'

import { SectionEventProps } from '@_types/landings'

import arabianImage from '@images/flags_3d/arabian.png'
import chineseImage from '@images/flags_3d/chinese.png'
import franceImage from '@images/flags_3d/france.png'
import germanyImage from '@images/flags_3d/germany.png'
import italyImage from '@images/flags_3d/italy.png'
import koreaImage from '@images/flags_3d/korea.png'
import portugalImage from '@images/flags_3d/portugal.png'
import spainImage from '@images/flags_3d/spain.png'
import ukraineImage from '@images/flags_3d/ukraine.png'
import usImage from '@images/flags_3d/us.png'

import FlagItem from '@templates/MainLandingV3/FlagsSection/FlagItem/FlagItem'

import styles from './flags_section.module.scss'

interface Props {
  title: string
  event?: SectionEventProps
}

const flagsItem = [
  usImage,
  portugalImage,
  italyImage,
  arabianImage,
  spainImage,
  franceImage,
  chineseImage,
  koreaImage,
  ukraineImage,
  germanyImage,
]
const FlagsSection: FC<Props> = ({ title, event }) => {
  const handleSpeed = (item: StaticImageData): number => {
    const moreSpeedItems = [usImage, spainImage, koreaImage]

    return moreSpeedItems.includes(item) ? 6 : 3
  }

  return (
    <section
      className={styles.section}
      data-place={event?.place}
      ref={event?.ref}
    >
      <h2 className={styles.title}>{title}</h2>
      {flagsItem.map((item) => (
        <FlagItem key={item.src} src={item} speed={handleSpeed(item)} />
      ))}
    </section>
  )
}

export default FlagsSection
