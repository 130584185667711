import { StaticImageData } from 'next/dist/client/image'
import Image from 'next/image'
import React, { FC, useRef } from 'react'
import { Parallax } from 'react-scroll-parallax'

import useBounceAnimation from '@hooks/useBounceAnimation'

import styles from './flag_item.module.scss'

interface Props {
  src: StaticImageData
  speed?: number
  enableBounce?: boolean
}

const FlagItem: FC<Props> = ({ src, speed = 3, enableBounce = true }) => {
  const figureRef = useRef<HTMLElement>(null)

  const imageClassName = useBounceAnimation({
    ref: figureRef,
    className: styles.flags,
  })

  return (
    <Parallax className={styles.figure} speed={speed}>
      <figure className={enableBounce ? imageClassName : ''} ref={figureRef}>
        <Image src={src} alt="" />
      </figure>
    </Parallax>
  )
}

export default FlagItem
