import classNames from 'classnames'
import Image from 'next/image'
import { FC, useRef } from 'react'
import { useParallax } from 'react-scroll-parallax'

import { SectionProps, StatsSectionTypes } from '@_types/landings'

import useBounceAnimation from '@hooks/useBounceAnimation'

import { ColorDataTypes } from '@utils/strapiCommons/types'
import { getColor } from '@utils/strapiCommons/utils'

import instagramImage from '@images/who-we-are/instagram.png'
import ratingImage from '@images/who-we-are/rating.png'

import styles from './stats_section.module.scss'

const defaultColor: ColorDataTypes = {
  name: 'White',
  opacity: 100,
}

type Props = {
  stats: StatsSectionTypes
  color?: ColorDataTypes
} & SectionProps

const StatsSection: FC<Props> = ({
  stats,
  event,
  order,
  disabled,
  color,
  className,
  title,
}) => {
  const {
    title: defaultTitle,
    totalCounter,
    totalText,
    instagramCounter,
    lessonsCounter,
    lessonsText,
    ratingCounter,
    ratingText,
    instagramText,
    isDisabled,
  } = stats
  const instagramImageRef = useRef(null)
  const ratingImageRef = useRef(null)
  const instagramImageParallax = useParallax({
    speed: 3,
  })
  const ratingImageParallax = useParallax({
    speed: 3,
  })

  const instagramImageClassName = useBounceAnimation({
    ref: instagramImageRef,
    className: styles.icon_instagram,
  })

  const ratingImageClassName = useBounceAnimation({
    ref: ratingImageRef,
    className: styles.icon_rating,
  })

  if (disabled || isDisabled) return null

  const sectionColor = getColor(color || defaultColor)

  const sectionClassName = classNames(styles.section, className)

  return (
    <section
      className={sectionClassName}
      data-place={event?.place}
      ref={event?.ref}
      style={{ order, backgroundColor: sectionColor }}
    >
      <h2 className={styles.title}>{title || defaultTitle}</h2>
      <div className={styles.wrapper}>
        <div className={styles.item}>
          <div className={styles.item_counter}>{totalCounter}</div>
          <p className={styles.item_title}>{totalText}</p>
        </div>
        <div className={styles.item}>
          <div className={styles.item_counter}>
            {instagramCounter}
            <figure
              className={styles.icon_instagram_wrapper}
              ref={instagramImageParallax.ref}
            >
              <div ref={instagramImageRef} className={instagramImageClassName}>
                <Image src={instagramImage} alt="" layout="fill" />
              </div>
            </figure>
          </div>
          <p className={styles.item_title}>{instagramText}</p>
        </div>
        <div className={styles.item}>
          <div className={styles.item_counter}>
            {ratingCounter}
            <figure
              className={styles.icon_rating_wrapper}
              ref={ratingImageParallax.ref}
            >
              <div ref={ratingImageRef} className={ratingImageClassName}>
                <Image src={ratingImage} alt="" layout="fill" />
              </div>
            </figure>
          </div>
          <p className={styles.item_title}>{ratingText}</p>
        </div>
        <div className={styles.item}>
          <div className={styles.item_counter}>{lessonsCounter}</div>
          <p className={styles.item_title}>{lessonsText}</p>
        </div>
      </div>
    </section>
  )
}

export default StatsSection
