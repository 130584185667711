import Image from 'next/image'
import { FC, useState } from 'react'
import { useParallax } from 'react-scroll-parallax'

import { SectionVideoTypes, Spinner } from '@_types/landings'

import iphoneScreen from '@images/free_for_ukraine/iphone-1.png'
import pixelScreen from '@images/free_for_ukraine/pixel-1.png'

import StrapiSpinner from '@templates/Landing/elements/StrapiSpinner/StrapiSpinner'
import ModalWithYoutube from '@templates/Landing/layoutV2/ModalWithYoutube/ModalWithYoutube'

import styles from './hero_right_block.module.scss'

type Props = {
  watchVideoSpinner: Spinner
  videoProps: SectionVideoTypes
  onCtaBtnClick?: () => void
}

const LEFT_IMAGE_PARALLAX_SPEED = 20
const RIGHT_IMAGE_PARALLAX_SPEED = -20
const HeroRightBlock: FC<Props> = ({
  watchVideoSpinner,
  videoProps,
  onCtaBtnClick,
}) => {
  const leftImageParallax = useParallax({
    speed: LEFT_IMAGE_PARALLAX_SPEED,
  })
  const rightImageParallax = useParallax({
    speed: RIGHT_IMAGE_PARALLAX_SPEED,
  })

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const handleClickSpinner = () => {
    if (onCtaBtnClick) {
      onCtaBtnClick()
    }
    setIsPopupOpen(true)
  }

  return (
    <div className={styles.pink_block}>
      <ModalWithYoutube
        isOpen={isPopupOpen}
        videoProps={videoProps}
        onClose={() => setIsPopupOpen(false)}
      />
      <figure
        className={`${styles.image_wrapper} ${styles.left_image}`}
        ref={leftImageParallax.ref}
      >
        <Image src={iphoneScreen} alt="" layout="fill" unoptimized />
      </figure>
      <figure
        className={`${styles.image_wrapper} ${styles.right_image}`}
        ref={rightImageParallax.ref}
      >
        <Image src={pixelScreen} alt="" layout="fill" unoptimized />
      </figure>
      {watchVideoSpinner && (
        <button onClick={handleClickSpinner} type="button">
          <figure
            key={watchVideoSpinner.id}
            className={styles.spinning_text_wrapper}
          >
            <StrapiSpinner spinner={watchVideoSpinner} isBackground={false} />
          </figure>
        </button>
      )}
    </div>
  )
}

export default HeroRightBlock
