import { FC } from 'react'

import styles from './title.module.scss'

type Headings = 'h1' | 'h2'

type Props = {
  heading?: Headings
  position?: 'left' | 'center'
  children?: string
  className?: string
}

const Title: FC<Props> = ({
  heading = 'h2',
  position = 'left',
  children,
  className,
}) => {
  const HeadingTag = heading

  return (
    <HeadingTag
      className={`${styles[position]} ${styles[heading]} ${className}`}
    >
      {children}
    </HeadingTag>
  )
}

export default Title
