import classNames from 'classnames'
import debounce from 'lodash.debounce'
import { FC, ReactNode, useEffect, useRef, useState } from 'react'

import styles from './fade_animation.module.scss'

type TriggerType = 'top' | 'bottom'

interface Props {
  children: ReactNode
  className?: string
  handler?: () => void
  isDisabled?: boolean
  triggerType?: TriggerType
}

const triggerTopPercent = 0.7 // 70%

const permittedMethods: TriggerType[] = ['top', 'bottom']

const getElementShowStatus = (element: HTMLDivElement, type: TriggerType) => {
  if (!permittedMethods.includes(type)) return false

  const windowSizesByType: Record<TriggerType, number> = {
    top: window.innerHeight * triggerTopPercent,
    bottom: window.innerHeight,
  }

  return element.getBoundingClientRect()[type] < windowSizesByType[type]
}

const FadeAnimation: FC<Props> = ({
  children,
  className = '',
  handler,
  isDisabled,
  triggerType = 'bottom',
}) => {
  const [isShowElement, setIsShowElement] = useState(isDisabled)
  const wrapRef = useRef<HTMLDivElement>(null)

  const resultClassNames = classNames(styles.item, className, {
    [styles.item_visible]: isShowElement || isDisabled,
  })

  useEffect(() => {
    const handleScroll = debounce(() => {
      const element = wrapRef.current

      if (element) {
        const showElement = getElementShowStatus(element, triggerType)
        setIsShowElement(showElement)
        if (handler && showElement) handler()
      }
    }, 20)

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handler, triggerType, wrapRef])

  return (
    <div ref={isDisabled ? null : wrapRef} className={resultClassNames}>
      {children}
    </div>
  )
}

export default FadeAnimation
