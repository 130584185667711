import { FC, useRef } from 'react'
import Image from 'next/image'
import { Parallax } from 'react-scroll-parallax'

import useBounceAnimation from '@hooks/useBounceAnimation'

import rocketImage from './images/rocketImage.png'
import phonesImage from './images/phonesImage.png'

import styles from './take_section.module.scss'

interface Props {
  takeTitle: string
}
const TakeSection:FC<Props> = ({ takeTitle })=> {
  const rocketRef = useRef<HTMLElement>(null)

  const rocketImageClassName = useBounceAnimation({
    ref: rocketRef,
  })

  return (
    <section className={styles.section}>
      <div className={styles.title_wrap}>
        <h2 className={styles.title}>
          {takeTitle}
        </h2>
        <Parallax speed={3} className={styles.rocket}>
          <figure className={rocketImageClassName} ref={rocketRef}>
            <Image
              src={rocketImage}
              alt=''
            />
          </figure>
        </Parallax>
      </div>
      <div className={styles.image_wrap}>
        <Parallax speed={5} className={styles.phones}>
          <figure>
            <Image
              src={phonesImage}
              alt=''
            />
          </figure>
        </Parallax>
      </div>
    </section>
  )
}

export default TakeSection
