import { FC, MouseEvent } from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'

import { useAppSelector } from '@reduxStore/hooks'

import {
  CLICKED_PLAY_VIDEO,
  CTA_BUTTON_LOCATION,
  HOME_PATH,
  LANDING_CLICKED_CTA,
  LANDING_NAME_MAIN_PAGE,
  START_ONBOARDING,
} from '@constants'

import {
  MainLandingPageTypes,
  SectionVideoTypes,
  StatsSectionTypes,
} from '@_types/landings'

import useAddBodyClassName from '@hooks/useAddBodyClassName'

import { sendAnalytics } from '@utils/analytics'

import FAQSection from '@templates/Landing/layoutV2/FAQSection/FAQSection'
import HeroSection from '@templates/Landing/layoutV2/HeroSection/HeroSection'
import StatsSection from '@templates/Landing/layoutV2/StatsSection/StatsSection'
import ApproachSection from '@templates/MainLandingV3/ApproachSection/ApproachSection'
import FlagsSection from '@templates/MainLandingV3/FlagsSection/FlagsSection'
import MediaSection from '@templates/MainLandingV3/MediaSection/MediaSection'
import ReviewsSlider from '@templates/MainLandingV3/ReviewsSlider/ReviewsSlider'
import TakeSection from '@templates/MainLandingV3/TakeSection/TakeSection'
import TrySection from '@templates/MainLandingV3/TrySection/TrySection'
import WhatFindSection from '@templates/MainLandingV3/WhatFindSection/WhatFindSection'
import HeroRightBlock from '@templates/MainLandingV3/layout/HeroRightBlock/HeroRightBlock'

import styles from './main-landing-v3.module.scss'

interface Props {
  page: MainLandingPageTypes
  stats: StatsSectionTypes
}

const MainLanding: FC<Props> = ({ page, stats }) => {
  useAddBodyClassName()

  const amplitudeInited = useAppSelector(
    (state) => state?.amplitude?.amplitudeInited
  )

  const handleSendCtaClickEvent = (e: MouseEvent<HTMLElement>) => {
    const { place, type, text } = e.currentTarget.dataset

    if (amplitudeInited) {
      sendAnalytics({
        eventName: LANDING_CLICKED_CTA,
        data: {
          place,
          type,
          text,
          landing_name: LANDING_NAME_MAIN_PAGE,
        },
      })
    }
  }

  const handleSendVideoOpenEvent = () => {
    if (amplitudeInited) {
      sendAnalytics({
        eventName: CLICKED_PLAY_VIDEO,
        data: {
          page_path: HOME_PATH,
          place: LANDING_NAME_MAIN_PAGE,
          video_name: page.sectionVideoId,
        },
      })
    }
  }

  const videoProps: SectionVideoTypes = {
    sectionVideoTextBefore: page.sectionVideoTextBefore,
    sectionVideoTextAfter: page.sectionVideoTextAfter,
    sectionVideoId: page.sectionVideoId,
    sectionVideoPreview: page.sectionVideoPreview,
    sectionVideoOrder: page.sectionVideoOrder,
    sectionVideoDisabled: page.sectionVideoDisabled,
  }

  if (!page) return null

  return (
    <div className={styles.wrapper}>
      <ParallaxProvider>
        <HeroSection
          title={page.title}
          description={{
            showed: page.descriptionShowed,
          }}
          button={{
            link: page.mainButtonLink,
            text: page.mainButtonText,
            iconName: 'arrow_right',
            'data-text': page.mainButtonText,
            'data-place': CTA_BUTTON_LOCATION.hero,
            'data-type': START_ONBOARDING,
            column: 'left',
            onClick: handleSendCtaClickEvent,
          }}
          image={page.mainImage}
          renderRightColumnContent={() => (
            <HeroRightBlock
              watchVideoSpinner={page.watchVideoSpinner}
              videoProps={videoProps}
              onCtaBtnClick={handleSendVideoOpenEvent}
            />
          )}
          wrappersClassNames={{
            right: styles.hero_right_content,
            left: styles.hero_left_content,
            button: styles.hero_button,
            leftColumn: styles.hero_left_column,
          }}
          fit="cover"
          position="top"
        />
        <WhatFindSection
          slidesTitleAfterGif={page.slidesTitleAfterGif}
          slidesTitleBeforeGif={page.slidesTitleBeforeGif}
          cards={page.heroCards}
          slides={page.slides}
        />
        <MediaSection
          topMarqueeLogos={page.mediaTopMarquee}
          bottomMarqueeLogos={page.mediaBottomMarquee}
          mediaRecommendedResourcesLogos={page.mediaRecommendedResources}
          marqueeBlockTitle={page.mediaMarqueeBlockTitle}
          recommendedResourcesBlockTitle={
            page.mediaRecommendedResourcesBlockTitle
          }
        />
        <FlagsSection title={page.flagTitle} />
        <ReviewsSlider
          title={page.reviewsTitle}
          reviews={page.reviewsItems}
          trustPilotRating={stats.trustPilotRating}
          appStoreRating={stats.appStoreRating}
          googlePlayRating={stats.googlePlayRating}
        />
        <StatsSection title={page.statsTitle} stats={stats} />
        <TakeSection takeTitle={page.takeTitle} />
        <TrySection
          tryTitle={page.tryTitle}
          tryButtonUrl={page.tryButtonUrl}
          tryButtonText={page.tryButtonText}
          tryText={page.tryText}
          button={{
            onClick: handleSendCtaClickEvent,
            'data-text': page.tryButtonText,
            'data-place': CTA_BUTTON_LOCATION.ctaBlock,
            'data-type': START_ONBOARDING,
          }}
        />
        <ApproachSection
          approachTitle={page.approachTitle}
          approachList={page.approachList}
          offsetToHideFirstImage={4}
        />
        <FAQSection
          title={page?.faq?.title}
          faqSource={page.faq}
          titleClassName={styles.title_faq}
        />
      </ParallaxProvider>
    </div>
  )
}

export default MainLanding
