import { FC } from 'react'

import { ButtonProps } from '@_types/landings'

import Button from '@elements/ButtonV2/Button'
import StoreButton, {
  StoreButtonProps,
} from '@elements/StoreButton/StoreButton'

import styles from './try_section.module.scss'

interface Props {
  tryTitle: string
  tryButtonUrl: string
  tryButtonText: string
  tryText: string
  button?: ButtonProps
}

const stores: StoreButtonProps[] = [
  {
    className: `${styles.store_button} ${styles.apple_button}`,
    type: 'apple',
    theme: 'circle',
    'data-place': 'hero',
    'data-type': 'apple',
    'data-text': 'apple',
  },
  {
    className: styles.store_button,
    type: 'google',
    theme: 'circle',
    'data-place': 'hero',
    'data-type': 'googleplay',
    'data-text': 'googleplay',
  },
  {
    className: styles.store_button,
    type: 'huawei',
    theme: 'circle',
    'data-place': 'hero',
    'data-type': 'appgallery',
    'data-text': 'appgallery',
  },
]

const TrySection: FC<Props> = ({
  tryTitle,
  tryButtonUrl,
  tryButtonText,
  tryText,
  button,
}) => (
  <section className={styles.section}>
    <div className={styles.title_button_container}>
      <h2 className={styles.title}>{tryTitle}</h2>
      <Button
        className={styles.button}
        data-text={tryButtonText}
        link={tryButtonUrl}
        {...button}
      >
        {tryButtonText}
      </Button>
    </div>

    <div className={styles.divider} />
    <div>
      <p className={styles.text}>{tryText}</p>
      <div className={styles.store_circle_buttons}>
        {stores.map((store) => (
          <StoreButton key={store.type} {...store} />
        ))}
      </div>
    </div>
  </section>
)

export default TrySection
