import Image from 'next/image'
import { FC } from 'react'
import Marquee from 'react-fast-marquee'
import { useMediaQuery } from 'react-responsive'

import { StrapiRecord } from '@_types/landings'

import ImageWithEffects from '@elements/ImageWithEffects/ImageWithEffects'

import heartShapedHandsImage from './images/heart-shape-hands.png'
import styles from './media_section.module.scss'


interface Props {
  topMarqueeLogos: { data: StrapiRecord[] }
  bottomMarqueeLogos: { data: StrapiRecord[] }
  mediaRecommendedResourcesLogos: { data: StrapiRecord[] }
  marqueeBlockTitle: string
  recommendedResourcesBlockTitle: string
}

const MediaSection: FC<Props> = ({
  topMarqueeLogos,
  bottomMarqueeLogos,
  mediaRecommendedResourcesLogos,
  marqueeBlockTitle,
  recommendedResourcesBlockTitle,
}) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  })

  // adjust logos size based on the screen size to make them responsive
  const imageSizeCoefficient = isMobile ? 1.3505 : 1

  const topMarqueeRenderedLogos = topMarqueeLogos?.data?.map((logo) => (
    <figure key={logo.id} className={styles.marquee_item}>
      <Image
        src={logo.attributes.url}
        alt={logo.attributes.caption}
        width={logo.attributes.width / imageSizeCoefficient}
        height={logo.attributes.height / imageSizeCoefficient}
      />
    </figure>
  ))

  const bottomMarqueeRenderedLogos = bottomMarqueeLogos?.data?.map((logo) => (
    <figure key={logo.id} className={styles.marquee_item}>
      <Image
        src={logo.attributes.url}
        alt={logo.attributes.caption}
        width={logo.attributes.width / imageSizeCoefficient}
        height={logo.attributes.height / imageSizeCoefficient}
      />
    </figure>
  ))

  const recommendedResourcesLogos = mediaRecommendedResourcesLogos?.data?.map(
    (logo) => (
      <figure key={logo.id}>
        <Image
          src={logo.attributes.url}
          alt={logo.attributes.caption}
          width={logo.attributes.width / imageSizeCoefficient}
          height={logo.attributes.height / imageSizeCoefficient}
        />
      </figure>
    )
  )

  return (
    <section className={styles.section}>
      <div className={styles.image_wrap}>
        <div className={styles.marquee_right}>
          <Marquee direction="right">{topMarqueeRenderedLogos}</Marquee>
        </div>
        <div className={styles.marquee_left}>
          <Marquee>{bottomMarqueeRenderedLogos}</Marquee>
        </div>
        <h2 className={styles.title}>{marqueeBlockTitle}</h2>
        <ImageWithEffects
          image={heartShapedHandsImage}
          className={styles.heart_shaped_hands}
        />
      </div>

      <div className={styles.second_block}>
        <h2 className={`${styles.title} ${styles.subtitle}`}>
          {recommendedResourcesBlockTitle}
        </h2>
        <div className={styles.logos_wrap}>{recommendedResourcesLogos}</div>
      </div>
    </section>
  )
}

export default MediaSection
