import classNames from 'classnames'
import Image, { StaticImageData } from 'next/image'
import { FC, useRef } from 'react'
import { useParallax } from 'react-scroll-parallax'

import { StrapiRecord } from '@_types/landings'

import useBounceAnimation, { BounceOptions } from '@hooks/useBounceAnimation'

import StrapiImage from '@elements/StrapiImage/StrapiImage'

import styles from './image_with_effects.module.scss'

type Props = {
  image?: StaticImageData
  strapiImage?: StrapiRecord
  className?: string
  imageClassName?: string
  parallaxSpeed?: number
  bounceOptions?: BounceOptions
}

const DEFAULT_PARALLAX_SPEED = 3

const ImageWithEffects: FC<Props> = ({
  image,
  strapiImage,
  className,
  parallaxSpeed,
  imageClassName,
  bounceOptions,
}) => {
  const imageRef = useRef(null)

  const imageParallax = useParallax({
    speed: parallaxSpeed || DEFAULT_PARALLAX_SPEED,
  })

  const bounceClassName = useBounceAnimation({
    ref: imageRef,
    className: styles.image,
    options: bounceOptions,
  })

  const wrapperClassName = classNames(styles.image_wrapper, className)

  return (
    <figure className={wrapperClassName} ref={imageParallax.ref}>
      <div ref={imageRef} className={bounceClassName}>
        {image && (
          <Image className={imageClassName} src={image} alt="" layout="fill" />
        )}
        {strapiImage?.data && <StrapiImage image={strapiImage} layout="fill" />}
      </div>
    </figure>
  )
}

export default ImageWithEffects
