import classNames from 'classnames'
import { FC, useRef } from 'react'

import { useAppSelector } from '@reduxStore/hooks'

import { FaqQuestion } from '@_types/faq'
import { FAQBlock, SectionProps } from '@_types/landings'

import useLandingInfo from '@hooks/useLandingInfo'

import { sendAnalytics } from '@utils/analytics'
import createFaqArray from '@utils/createFaqArray'
import { scalingParam } from '@utils/helpers'

import FAQ from '@modules/FAQ/FAQ'

import styles from './faq_section.module.scss'

export type FAQSectionProps = {
  titleClassName?: string
  faqSource: FAQBlock
  enableClickItemAnalytics?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  isDisabled?: boolean
} & SectionProps

const FaqSection: FC<FAQSectionProps> = ({
  title,
  titleClassName,
  faqSource,
  order,
  event,
  disabled,
  enableClickItemAnalytics = true,
}) => {
  const firstClick = useRef(true)

  const amplitudeInited = useAppSelector(
    (state) => state?.amplitude?.amplitudeInited
  )

  const { LANDING_PATH } = useLandingInfo()
  if (disabled) return null

  const faq: FaqQuestion[] =
    (faqSource && createFaqArray(faqSource, true)) || []

  const titleStyles = {
    fontFamily: 'var(--heading-font)',
    fontSize: '24px',
    fontWeight: '400',
    '@media (min-width: 1920px)': {
      fontSize: scalingParam(24),
    },
    '@media (max-width: 768px)': {
      fontSize: '20px',
    },
  }
  const containerStyles = {
    borderBottom: '1px solid #000',
    '@media (max-width: 768px)': {
      borderBottom: 'none',
    },
  }
  const itemStyles = {
    paddingTop: '30px',
    paddingBottom: '30px',
    '@media (min-width: 1920px)': {
      paddingTop: scalingParam(30),
      paddingBottom: scalingParam(30),
    },
  }

  const sectionTitleStyles = classNames(styles.title, {
    [titleClassName as string]: !!titleClassName,
  })

  if (!Array.isArray(faq) || !faq.length) return null

  const handleClickItem = () => {
    if (amplitudeInited && enableClickItemAnalytics && firstClick.current) {
      sendAnalytics({
        eventName: 'gen_clicked_show_full_content',
        data: {
          page_path: LANDING_PATH,
          place: 'FAQ',
          redirect: 'false',
        },
      })
      firstClick.current = false
    }
  }

  return (
    <section
      className={styles.section}
      data-place={event?.place}
      ref={event?.ref}
      style={{ order }}
    >
      <div className={styles.left}>
        <h2 className={sectionTitleStyles}>{title}</h2>
      </div>
      <div className={styles.right}>
        <FAQ
          questions={faq}
          variant="line"
          isAnswerExtended
          customStyles={{
            title: titleStyles,
            item: itemStyles,
            container: containerStyles,
          }}
          answerClassName={styles.text}
          questionTag="h3"
          onClickItem={handleClickItem}
        />
      </div>
    </section>
  )
}

export default FaqSection
