import { FC } from 'react'

import sortByOrder from '@promova/utils/sortByOrder'

import { Review, SectionProps } from '@_types/landings'

import { ColorDataTypes } from '@utils/strapiCommons/types'
import { getColor } from '@utils/strapiCommons/utils'

import Title from '@templates/Landing/GroupClasses/layout/Title/Title'
import ScrollerWithReviews from '@templates/Landing/layoutV2/ReviewsSlider/ScrollerWithReviews/ScrollerWithReviews'

import styles from './reviews_slider.module.scss'

type Props = {
  reviews: Review[]
  appStoreRating: number | string
  googlePlayRating: number | string
  trustPilotRating: number | string
  color?: ColorDataTypes
} & SectionProps

const defaultColor: ColorDataTypes = {
  name: 'Gray',
  opacity: 7,
}

const ReviewsSlider: FC<Props> = ({
  title,
  reviews,
  trustPilotRating,
  googlePlayRating,
  appStoreRating,
  color,
  event,
  order,
  disabled,
}) => {
  if (disabled) return null

  const sectionColor = getColor(color || defaultColor)

  return (
    <section
      className={styles.container}
      data-place={event?.place}
      ref={event?.ref}
      style={{ order, backgroundColor: sectionColor }}
    >
      <Title className={styles.title}>{title}</Title>
      <ScrollerWithReviews
        reviews={sortByOrder(reviews)}
        appStoreRating={appStoreRating}
        googlePlayRating={googlePlayRating}
        trustPilotRating={trustPilotRating}
      />
    </section>
  )
}

export default ReviewsSlider
