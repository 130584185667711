import { useEffect, useRef } from 'react'

interface WheelEvent extends MouseEvent {
  deltaMode: number
  deltaX: number
  deltaY: number
  deltaZ: number
}

export default function useHorizontalScroll() {
  const elRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const el = elRef.current
    if (el) {
      const onWheel = (e: WheelEvent) => {
        if (e.deltaY === 0) return
        if (e.deltaY > 0 && el.scrollLeft >= el.scrollWidth - el.clientWidth)
          return
        if (e.deltaY < 0 && el.scrollLeft === 0) return
        e.preventDefault()
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
        })
      }
      el.addEventListener('wheel', onWheel)
      return () => el.removeEventListener('wheel', onWheel)
    }

    return undefined
  }, [])

  return elRef
}
