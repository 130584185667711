import { FC, useEffect, useRef, useState } from 'react'

import LogoAnimated from '@promova/ui/components/LogoAnimated/LogoAnimated'

import styles from './loader.module.scss'

interface Props {
  isLoading: boolean
  withOverlay?: boolean
  delay?: number
}

const Loader: FC<Props> = ({ isLoading, withOverlay = false, delay = 300 }) => {
  const [showLoader, setShowLoader] = useState(false)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    if (isLoading && delay) {
      timer.current = setTimeout(() => {
        setShowLoader(true)
      }, delay)
    }

    if (!isLoading && timer?.current) {
      clearTimeout(timer?.current)
    } else if (!isLoading) {
      setShowLoader(false)
    }

    return () => {
      if (timer?.current) {
        clearTimeout(timer?.current)
      }
      setShowLoader(false)
    }
  }, [isLoading, delay])

  switch (true) {
    case !showLoader: {
      return null
    }

    case withOverlay: {
      return (
        <div className={styles.overlay}>
          <div className={styles.loader_wrapper}>
            <LogoAnimated />
          </div>
        </div>
      )
    }

    default: {
      return (
        <div className={styles.loader_wrapper}>
          <LogoAnimated />
        </div>
      )
    }
  }
}
export default Loader
