import { Trans } from '@lingui/macro'
import classnames from 'classnames'
import parse from 'html-react-parser'
import { FC, MouseEvent, useMemo } from 'react'
import Lottie from 'react-lottie-player'

import sortByOrder from '@promova/utils/sortByOrder'

import { useAppSelector } from '@reduxStore/hooks'

import { LANDING_CLICKED_CTA, LANDING_NAME_MAIN_PAGE } from '@constants'

import { CardV2, SlideItemTypes } from '@_types/landings'

import { sendAnalytics } from '@utils/analytics'

import LearningOptionsLottie from '@templates/MainLandingV3/WhatFindSection/img/learning-options-lottie.json'

import Button from '@elements/ButtonV2/Button'
import FadeAnimation from '@elements/FadeAnimation/FadeAnimation'
import FitSizeText from '@elements/FitSizeText/FitSizeText'
import ImageWithEffects from '@elements/ImageWithEffects/ImageWithEffects'
import StrapiImage from '@elements/StrapiImage/StrapiImage'

import styles from './what-find-section.module.scss'

interface Props {
  slidesTitleAfterGif: string
  slidesTitleBeforeGif: string
  slides: SlideItemTypes[]
  cards: CardV2[]
}

const WhatFindSection: FC<Props> = ({
  slidesTitleAfterGif,
  slidesTitleBeforeGif,
  slides,
  cards,
}) => {
  const sortedSlides = useMemo(() => sortByOrder(slides), [slides])
  const sortedCards = useMemo(() => sortByOrder(cards), [cards])

  const getImageWrapClassNames = (item: number) =>
    classnames(styles.image_wrap, {
      [styles[`image_wrap_${item}`]]: item >= 1 && item <= 3,
    })

  const getCardImageWrapClassNames = (item: number) =>
    classnames(styles.card_image_wrap, {
      [styles[`card_image_wrap_${item}`]]: item >= 1 && item <= 6,
    })

  const firstRowCards = sortedCards.slice(0, 3)
  const secondRowCards = sortedCards.slice(3, 5)
  const thirdRowCards = sortedCards.slice(5, 8)

  const amplitudeInited = useAppSelector(
    (state) => state?.amplitude?.amplitudeInited
  )
  const handleSendCtaClickEvent = (e: MouseEvent<HTMLElement>) => {
    const { place, type, text } = e.currentTarget.dataset

    if (amplitudeInited) {
      sendAnalytics({
        eventName: LANDING_CLICKED_CTA,
        data: {
          place,
          type,
          text,
          landing_name: LANDING_NAME_MAIN_PAGE,
        },
      })
    }
  }

  return (
    <section className={styles.section}>
      <div className={styles.title_wrap}>
        <h2 className={`${styles.title} ${styles.title_inline}`}>
          {slidesTitleBeforeGif}
        </h2>
        <figure className={styles.gif}>
          <Lottie animationData={LearningOptionsLottie} loop play />
        </figure>

        <h2 className={`${styles.title} ${styles.title_inline}`}>
          {slidesTitleAfterGif}
        </h2>
      </div>

      <div className={styles.wrap}>
        {sortedSlides.map(
          (
            {
              title,
              text,
              buttonText,
              buttonUrl,
              isShowCurrently,
              image,
              flag,
              video,
            }: any,
            index: number
          ) => (
            <div key={title} className={styles.item_wrapper}>
              {/* cards */}
              <div className={styles.item} data-number={index}>
                <div className={styles.content_wrap}>
                  <FadeAnimation>
                    <p className={styles.number}>— 0{index + 1}</p>
                  </FadeAnimation>
                  <FadeAnimation>
                    <h3 className={styles.subtitle}>
                      <FitSizeText text={parse(title)} />
                    </h3>
                  </FadeAnimation>
                  <FadeAnimation>
                    <p className={styles.text}>{parse(text)}</p>
                  </FadeAnimation>
                  {isShowCurrently && (
                    <FadeAnimation>
                      <div className={styles.currently}>
                        <figure className={styles.flag}>
                          <StrapiImage image={flag} layout="fill" fit="cover" />
                        </figure>
                        <Trans>
                          Currently available only for English learners
                        </Trans>
                      </div>
                    </FadeAnimation>
                  )}
                  <FadeAnimation>
                    <Button
                      className={styles.button}
                      link={buttonUrl}
                      data-place={title}
                      data-type={
                        buttonUrl === '/group-lessons'
                          ? 'group lessons'
                          : 'start onboarding'
                      }
                      data-text={buttonText}
                      onClick={handleSendCtaClickEvent}
                    >
                      {buttonText}
                    </Button>
                  </FadeAnimation>
                </div>
                <div className={getImageWrapClassNames(index + 1)}>
                  <StrapiImage image={image} layout="fill" fit="cover" />
                  {video?.data?.attributes?.url && (
                    <video
                      className={styles.video}
                      loop
                      autoPlay
                      muted
                      playsInline
                    >
                      <track default kind="captions" srcLang="en" />
                      <source
                        src={video.data.attributes.url}
                        type="video/mp4"
                      />
                    </video>
                  )}
                </div>
              </div>
              {index === 0 && (
                <div
                  className={classnames(
                    styles.card_row,
                    styles.card_row_first,
                    styles.card
                  )}
                >
                  {firstRowCards.map((card, cardIndex: number) => (
                    <div
                      key={card.title}
                      className={`${styles.card_element} ${
                        styles[`card_element_${cardIndex + 1}`]
                      }`}
                    >
                      {card.image.data && (
                        <ImageWithEffects
                          strapiImage={card.image}
                          className={getCardImageWrapClassNames(cardIndex + 1)}
                        />
                      )}
                      <h3 className={styles.card_title}>{card.title}</h3>
                      <div className={styles.card_body}>{parse(card.text)}</div>
                    </div>
                  ))}
                </div>
              )}

              {index === 1 && (
                <div
                  className={classnames(
                    styles.card_row,
                    styles.card_row_second
                  )}
                >
                  {secondRowCards.map((card, cardIndex: number) => (
                    <div
                      key={card.title}
                      className={`${styles.card_element} ${
                        styles[`card_element_${cardIndex + 1}`]
                      }`}
                    >
                      {card.image.data && (
                        <ImageWithEffects
                          className={getCardImageWrapClassNames(cardIndex + 4)}
                          strapiImage={card.image}
                        />
                      )}
                      <h3 className={styles.card_title}>{card.title}</h3>
                      <div className={styles.card_body}>{parse(card.text)}</div>
                    </div>
                  ))}
                </div>
              )}

              {index === 2 && (
                <div
                  className={classnames(
                    styles.card_row,
                    styles.card_row_third,
                    styles.card,
                    styles.last_card_row
                  )}
                >
                  {thirdRowCards.map((card, cardIndex: number) => (
                    <div
                      key={card.title}
                      className={`${styles.card_element} ${
                        styles[`card_element_${cardIndex + 1}`]
                      }`}
                    >
                      {card.image.data && (
                        <ImageWithEffects
                          strapiImage={card.image}
                          className={getCardImageWrapClassNames(cardIndex + 5)}
                        />
                      )}
                      <h3 className={styles.card_title}>{card.title}</h3>
                      <div className={styles.card_body}>{parse(card.text)}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )
        )}
      </div>
    </section>
  )
}

export default WhatFindSection
