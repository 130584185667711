import classNames from 'classnames'
import { FC } from 'react'

import { Spinner } from '@_types/landings'

import StrapiImage from '@elements/StrapiImage/StrapiImage'

import styles from './strapi_spinner.module.scss'

type Props = {
  spinner: Spinner
  isBackground?: boolean
}

const StrapiSpinner: FC<Props> = ({ spinner, isBackground = true }) => {
  const { circle, center, isSpinning } = spinner

  const circleClassNames = classNames(styles.circle, {
    [styles.animated_circle]: isSpinning,
  })

  const contentClassNames = classNames({
    [styles.background]: isBackground,
    [styles.content]: !isBackground,
  })

  return (
    <div className={styles.spinner}>
      <figure className={circleClassNames}>
        <StrapiImage image={circle} layout="fill" />
      </figure>
      <figure className={contentClassNames}>
        <StrapiImage image={center} layout="fill" />
      </figure>
    </div>
  )
}

export default StrapiSpinner
