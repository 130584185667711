import Image from 'next/image'
import { FC } from 'react'

import styles from './store_rate_card.module.scss'


type Props = {
  type?: 'app_store' | 'google_play' | 'trust_pilot'
  rate: number | string
}

const mapTypeToTitle = {
  app_store: 'App Store',
  google_play: 'Google Play',
  trust_pilot: 'Trust Pilot',
}

const StoreRateCard: FC<Props> = ({ type = 'app_store', rate }) => (
  <div className={styles.container}>
    <p className={styles.rate}>{rate}</p>
    <p className={styles.title}>{mapTypeToTitle[type]}</p>
    <figure className={styles.image_wrapper}>
      <Image
        src={`/images/group_classes/${type}.svg`}
        layout="fill"
        alt={type}
      />
    </figure>
  </div>
)

export default StoreRateCard
