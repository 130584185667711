import classNames from 'classnames'
import { FC, Fragment } from 'react'

import sortByOrder from '@promova/utils/sortByOrder'

import { Review } from '@_types/landings'

import HorizontalScroller from '@templates/Landing/layoutV2/HorizontalScroller/HorizontalScroller'
import ReviewItem from '@templates/MainLandingV3/ReviewsSlider/ReviewItem/ReviewItem'
import StoreRateCard from '@templates/MainLandingV3/ReviewsSlider/StoreRateCard/StoreRateCard'

import styles from './scroller-with-reviews.module.scss'

type Props = {
  reviews: Review[]
  appStoreRating: number | string
  googlePlayRating: number | string
  trustPilotRating: number | string
  appStoreOrder?: number
  googlePlayOrder?: number
  trustPilotOrder?: number
  scrollerClassName?: string
}

const ScrollerWithReviews: FC<Props> = ({
  reviews,
  scrollerClassName,
  appStoreRating,
  googlePlayRating,
  trustPilotRating,
  googlePlayOrder = 3,
  appStoreOrder = 2,
  trustPilotOrder = 1,
}) => {
  const scrollerClassnames = classNames(styles.scroller, scrollerClassName)
  return (
    <HorizontalScroller className={scrollerClassnames}>
      {sortByOrder(reviews).map((review) => (
        <Fragment key={review.id}>
          {review.order === trustPilotOrder && (
            <StoreRateCard rate={trustPilotRating} type="trust_pilot" />
          )}

          {review.order === appStoreOrder && (
            <StoreRateCard rate={appStoreRating} type="app_store" />
          )}
          {review.order === googlePlayOrder && (
            <StoreRateCard rate={googlePlayRating} type="google_play" />
          )}
          <ReviewItem key={review.id} review={review} />
        </Fragment>
      ))}
    </HorizontalScroller>
  )
}

export default ScrollerWithReviews
